import { render, staticRenderFns } from "./index.vue?vue&type=template&id=23b28d9a&scoped=true&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=23b28d9a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b28d9a",
  null
  
)

export default component.exports